import React from "react"
import GlobalStyle from "../components/GlobalStyle"
import MenuBar from "../components/layout/MenuBar"
import styled from "styled-components"
import { Helmet } from "react-helmet"
import { graphql } from "gatsby"
import { device } from "../utils/mediaQueries"

const TextContainer = styled.div`
  padding: 50px 150px;
  @media ${device.mobileL} {
    padding: 15px;
  }
`

const Template = ({ data }) => {
  return (
    <GlobalStyle>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{data.markdownRemark.frontmatter.metaTitle}</title>
        <meta
          name="description"
          content={data.markdownRemark.frontmatter.metaDesc}
        />
        <html lang="fi" />
      </Helmet>
      <MenuBar bg={"#337ab7"} items={data.allMarkdownRemark.edges} />
      <TextContainer
        dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }}
      ></TextContainer>
    </GlobalStyle>
  )
}
export const query = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        filter
        img
        metaDesc
        metaTitle
        path
        name
        title
        urlFix
      }
    }
    allMarkdownRemark(
      filter: { frontmatter: { parent: { eq: "frontpage" } } }
    ) {
      edges {
        node {
          frontmatter {
            filter
            path
            name
          }
        }
      }
    }
  }
`
export default Template
